import { mapValues } from 'lodash';
import { Model as BaseModel, type HTTPRequestConfig } from 'vue-api-query';

export default class Model extends BaseModel {
  // The meta object to store meta information about the response
  public meta: any = {};

  baseURL() {
    return useRuntimeConfig().public.apiURL;
  }

  // Method to process the response and convert it to a POJO if necessary
  public static processResponse(response: any): any {
    if (response && typeof response === 'object') {
      if (Array.isArray(response)) {
        return response.map((item: any) => ({
          ...item,
        }));
      } else {
        return {
          ...response,
        };
      }
    }
    return response;
  }

  async request(config: HTTPRequestConfig) {
    const configUrl = config.url?.endsWith('/') ? config.url.slice(0, -1) : config.url;

    const response = await this.$http(configUrl, {
      method: config.method,
      body: this.getBody(config),
    });

    this.meta = response?.meta;

    return response;
  }

  getBody(config: HTTPRequestConfig) {
    if (config.data instanceof FormData) {
      return config.data;
    }
    if (config.data) {
      return mapValues(config.data);
    }
  }

  formData() {
    return {
      indices: true,
      booleansAsIntegers: true,
      nullsAsUndefineds: false,
      allowEmptyArrays: false,
    };
  }
}
